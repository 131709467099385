<template>
  <div class="start">
    <BlenderSplash />
    <div class="section -text-center">
      <div class="content">
        <h1>Mixa & Vinn</h1>
        <strong class="-h3">Tävla om en lyxig blender</strong>
        <p class="-body-1">Är du ett smoothieproffs? Då har du chans att vinna drömblendern från Vitamix. Välj dina favoriter och hemliga ingredienser och släng ner i blendern. Mixa, toppa med lite crunch och du har skapat din perfekta smoothiebowl.</p>
        <router-link :to="hasDisplayedInstructions ? '/tavla/valj-ingredienser' : '/tavla/info'">
          <Button>{{ hasDisplayedInstructions ? 'Starta & välj ingredienser' : 'Så tävlar du' }}</Button>
        </router-link>
      </div>
      <img src="@/assets/img/pauluns-bg.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BlenderSplash, Button } from '@/components'

export default {
  components: {
    BlenderSplash,
    Button,
  },
  computed: {
    ...mapGetters({
      hasDisplayedInstructions: 'recipe/hasDisplayedInstructions',
    }),
  },
}
</script>

<style lang="scss" scoped>
.start {
  display: flex;
  flex: 1;
  flex-direction: column;
}

h1 {
  @include rem(margin-bottom, 0px);
}

.-h3 {
  @include rem(font-size, 18px);
  @include rem(line-height, 26px);
  @include rem(margin-bottom, 8px);
  display: inline-block;
}

p {
  @include rem(margin-bottom, $gutter);
}

img {
  @include rem(max-width, 768px);
  width: 100%;
  object-fit: cover;
  margin: auto auto 0;
}

/deep/ .blender-splash {
  z-index: 2;
}

.-body-1 {
  @include rem(font-size, 16px);
  @include rem(line-height, 24px);
}

.section {
  @include rem(padding-top, $gutter * 2.5);
  margin: 0;
  margin-top: -50px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  max-width: none;
  background-color: $cream-white;
  z-index: 1;
}

.content {
  @include rem(padding, 0 $gutter);
}

.btn {
  @include rem(margin-bottom, $gutter);
}

@media #{$mobile-xs} {

  .section {
    margin-top: -70px;
  }

  .content {
    @include rem(padding, 0 $gutter);
  }

  .-h3 {
    @include rem(margin-bottom, 6px);
  }

  .-body-1 {
    @include rem(font-size, 14px);
    @include rem(line-height, 22px);
    @include rem(margin-bottom, $gutter / 2);
  }

  /deep/ .blender-splash {
    img {
      max-width: 180px;
    }
  }
}

@media #{$desktop-sm} {
  .section {
    @include rem(padding-top, $gutter * 2.5);
    margin-top: -50px;
  }
}

@media #{$desktop-sm} {
  .section {
    margin-top: -50px !important;
  }

  .content {
    margin-top: -40px;
  }
}

@media #{$desktop-sm} {
  .content {
    margin-top: -50px;
  }
}

@media #{$wide} {
  .section {
    @include rem(padding-top, $gutter * 5);
    margin-top: -100px;
  }

  .content {
    padding: 0;
  }

  .-h3 {
    @include rem(line-height, 36px);
    @include rem(margin-bottom, 16px);
    font-size: 1.68rem;
  }

  .-body-1 {
    @include rem(font-size, 18px);
    @include rem(line-height, 26px);
  }
}

</style>